<template>
  <v-card class="elevation-0">
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-overlay :value="overlayAttachments">
      <v-progress-circular :rotate="180" :width="5" :value="loaderPercentage" color="white" size="120"> {{ count + 1 }} /{{ selectedFiles.length }} </v-progress-circular>
      <div class="PrimaryFontColorXSmall">Uploading Attachments...</div>
    </v-overlay>
    <v-toolbar color="primary" dark dense class="elevation-0">
      Report An Issue
      <v-spacer></v-spacer>
      <v-btn icon @click="goBack()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="8">
          <div align="center" class="flexClass" v-if="mounted == true" style="padding-top: 40px">
            <img ref="imgRef" width="100%" height="375px" class="flexClass" :src="imageSRC" @click="showMarkerArea" />
          </div>
          <v-card outlined width="100%" class="mx-2 mt-12 pa-2">
            <div class="font-weight-bold FontSize"><u>Adding Annotation Instructions</u></div>
            <div class="FontSize">1. Click on the Image to enable adding annotation.</div>
            <div class="FontSize">2. Select Any option from the toolbar.</div>
            <div class="FontSize">3. After the changes are made click on the top right tick to save the changes</div>
          </v-card>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12">
              <v-form ref="form">
                <v-textarea
                  dense
                  outlined
                  rows="3"
                  :counter="1000"
                  class="FontSize field_height field_label_size"
                  label="Description   *"
                  v-model="report_issue.issue_description"
                  :rules="[(v) => !!v || 'Required', (v) => (v && v.length <= 1000) || 'Description must be less than 1000 characters']"
                ></v-textarea>
                <v-select
                  dense
                  outlined
                  class="FontSize field_height field_label_size"
                  label="Sub-System"
                  :items="sub_systems"
                  item-text="text"
                  item-value="value"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="report_issue.issue_reported_platform"
                ></v-select>
                <!-- <v-file-input
                  dense
                  v-model="selectedFiles"
                  color="primary accent-4"
                  counter
                  multiple
                  class="FontSize field_height field_label_size"
                  label="Upload Attachments"
                  prepend-icon=""
                  :rules="[(v) => !!v || 'Required']"
                  prepend-inner-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip x-small v-if="selectedFiles.length != 0 && index < 1" color="primary" dark label small>
                      <div class="FontSizeSmall">{{ text.slice(0, 20) }}</div>
                    </v-chip>

                    <span v-else-if="index === 1" class="text-overline grey--text text--darken-3 mx-2"> +{{ selectedFiles.length - 1 }} File(s) </span>
                  </template>
                </v-file-input> -->
              </v-form>
            </v-col>
          </v-row>
          <v-card-actions class="pr-6 pb-4">
            <v-spacer></v-spacer>
            <!-- <v-btn small outlined color="primary" @click="dialogCreateIssueEmit(1)">Cancel</v-btn> -->
            <v-btn small outlined color="primary" @click="goBack()">Cancel</v-btn>
            <v-btn small color="primary" :loading="loading" @click="validateMethod()">Report</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import * as markerjs2 from "markerjs2";
var AWS = require("aws-sdk");
import { reportIssue } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
export default {
  components: { Snackbar },
  mixins: [GetEachProjectDetails],
  data: () => ({
    Toggle:0,
    msg: "",
    imageSRC: "",
    mounted: false,
    selectedFilesFromDrop: [],
    count: 0,
    overlayAttachments: false,
    report_issue: {
      issue_type: "",
      issue_module_name: "",
      issue_sub_module_name: "",
      issue_description: "",
      assign_to: "",
      issue_severity: "",
      issue_reported_platform: "",
      issue_reported_project_version: "",
    },
    loading: false,
    SnackBarComponent: {},
    selectedFiles: [],
    sub_systems: [],
    issue_attachments: [],
    issue_severityItems: [
      {
        text: "Not Specified",
        value: "NOT_SPECIFIED",
      },
      {
        text: "High",
        value: "HIGH",
      },
      {
        text: "Medium",
        value: "MEDIUM",
      },
      {
        text: "Low",
        value: "LOW",
      },
    ],
    loaderPercentage: 0,
    loaderPercentageDivider: 0,
  }),
  async mounted() {
    this.imageSRC = "";
    this.sub_systems = [];
    let result = await this.GetEachProjectDetailsMethod("1cd695c2-5287-45b2-84f1-6064dc125610");
    if (result.sub_systems.length != 0) {
      this.sub_systems = result.sub_systems.map((subsystem) => {
        let object = {};
        object.text = subsystem.project_platform;
        object.value = subsystem.project_platform;
        return object;
      });
    }
    this.sub_systems.push({
      text: "Not Specified",
      value: "NOT_SPECIFIED",
    });
    this.report_issue.issue_reported_platform = "NOT_SPECIFIED";
    this.report_issue.issue_severity = "NOT_SPECIFIED";
    this.selectedFilesFromDrop = this.$store.getters.get_issue_details.selectedFilesFromDrop;
    if (this.selectedFilesFromDrop && this.selectedFilesFromDrop.length != 0) {
      this.selectedFiles = this.selectedFilesFromDrop;
    }
    if (this.selectedFilesFromDrop.length == 1) {
      var fileReader = new FileReader();
      var self = this;
      self.mounted = false;
      fileReader.readAsDataURL(self.selectedFilesFromDrop[0]);
      fileReader.onload = async function (value) {
        console.log("Check The Value", value.target.result);
        self.imageSRC = value.target.result;
        self.mounted = true;
      };
    }
  },
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.selectedFiles.length != 0) {
          this.count = 0;
          this.loaderPercentage = 0;
          this.loaderPercentageDivider = 100 / this.selectedFiles.length;
          this.uploadImageToS3();
        } else {
          this.reportIssueMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Invalid Input(s)",
        };
      }
    },
    goBack() {
      this.$router.push(this.$store.getters.get_issue_details.routeName);
    },
    async uploadImageToS3() {
      this.loading = true;
      this.overlayAttachments = true;
      let s3Credentials = this.$store.getters.get_current_user_details.s3_details;
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: s3Credentials.region,
        accessKeyId: s3Credentials.access_key,
        secretAccessKey: s3Credentials.secret_key,
      });
      //   var fileReader = new FileReader();
      //   fileReader.readAsDataURL(self.selectedFiles[self.count]);
      //   fileReader.onload = async function () {
      // var base64FileContent = fileReader.result.split(",");
      var base64FileContent = self.imageSRC.split(",");
      var buf = Buffer.from(base64FileContent[1], "base64");
      var param = {
        Bucket: s3Credentials.bucket_name,
        Key: `BugTrakr/Issues/Attachments/${new Date().getTime()}_${self.selectedFiles[self.count].name}`,
        Body: buf,
        ContentType: self.selectedFiles.type,
        ACL: "public-read-write",
      };
      await s3Bucket.putObject(param, function (err, data) {
        if (err) {
          console.log(err);
          self.loading = false;
          self.overlayAttachments = false;
        } else if (data) {
          if (self.count != self.selectedFiles.length - 1) {
            self.count += 1;
            self.issue_attachments.push(`https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`);
            self.uploadImageToS3();
            self.loaderPercentage += self.loaderPercentageDivider;
          } else {
            self.issue_attachments.push(`https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`);
            self.reportIssueMethod(self.issue_attachments);
            self.loaderPercentage += 100;
          }
        }
      });
      //   };
    },
    async reportIssueMethod(issue_attachments) {
      var mutationname = reportIssue;
      var mutationresult = "ReportIssue";
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: "1cd695c2-5287-45b2-84f1-6064dc125610",
        issue_type: "BUG",
        issue_module_name: this.report_issue.issue_description.slice(0, 100),
        issue_description: this.report_issue.issue_description,
        issue_severity: "HIGH",
        issue_attachments: issue_attachments ? issue_attachments : undefined,
        issue_reported_platform: this.report_issue.issue_reported_platform && this.report_issue.issue_reported_platform != "" ? this.report_issue.issue_reported_platform : undefined,
        issue_reported_project_version:
          this.report_issue.issue_reported_project_version && this.report_issue.issue_reported_project_version != "" ? this.report_issue.issue_reported_project_version : undefined,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.goBack();
        }
        this.loading = false;
        this.overlayAttachments = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
        this.overlayAttachments = false;
      }
    },
    dialogCreateIssueEmit(Toggle){
    
    
      this.$emit("clicked", Toggle);
    },

    showMarkerArea() {
      const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
      markerArea.addEventListener("render", (event) => {
        this.$refs.imgRef.src = event.dataUrl;
        this.imageSRC = event.dataUrl;
      });
      markerArea.show();
    },
  },
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.flexClass {
  display: flex !important;
}
</style>
