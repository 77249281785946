import { listAssignedIssuesOfEachDeveloper } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuesCountOnDevelopers = {
  data() {
    return {
      checkLabelsToShow: false,
      GetAllIssuesCountOnDevelopersList: [],
      overlay: false,
      noDataText: "",
    };
  },
  methods: {
    async GetAllIssuesCountOnDevelopersMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listAssignedIssuesOfEachDeveloper, {
            input: {
              project_id: this.$store.getters.get_current_project_details.project_id,
            },
          })
        );
        this.GetAllIssuesCountOnDevelopersList = JSON.parse(result.data.ListAssignedIssuesOfEachDeveloper);
        this.seriesPie = [];
        this.chartOptionsPie.labels = [];
        this.GetAllIssuesCountOnDevelopersList.map((issues) => {
          this.seriesPie.push(issues.assigned_issue_details.Count);
          this.chartOptionsPie.labels.push(issues.developer_email);
        });
        this.checkLabelsToShow = this.seriesPie.filter((series) => series == 0).length == this.seriesPie.length ? false : true;
        if (this.GetAllIssuesCountOnDevelopersList.length == 0) {
          this.noDataText = "No Activities Found";
        }
        this.$forceUpdate();
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.noDataText = error.errors[0].message;
        console.log(error);
      }
    },
  },
};
