<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="regenerateAPIkeyvdialog" width="500">
      <v-card class="elevation-0">
        <v-toolbar dark dense color="primary" class="elevation-0">
          <div v-text="'Regenerate API key'"></div>
          <!-- <v-toolbar-title> Regenerate API key </v-toolbar-title> -->
          <v-spacer></v-spacer>
          <v-icon color="white" @click="regenerateAPIkeyEmit((Toggle = 1))">mdi-close</v-icon>
        </v-toolbar>
        <v-textarea
          class="pl-5 pt-5 px-5 FontSize"
          outlined
          readonly
          rows="2"
          height="100"
          value="Are you sure you want to regenerate the API Key ? If you have integrated with third-party system then you need to update it there as well.."
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small dark @click="regenerateAPIkeyEmit((Toggle = 1))" outlined><v-icon color="primary">mdi-close</v-icon>Cancel</v-btn>
          <v-btn color="primary" @click="regenerateAPIKeyMethod()" small dark><v-icon color="white">mdi-check</v-icon> Confirm</v-btn>
        </v-card-actions>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { regenerateProjectApiKey } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    Snackbar,
  },

  props: {
    regenerateAPIkeyvdialog: Boolean,
    storeObj: Object,
  },

  data: () => ({
    Toggle: 0,
    SnackBarComponent: false,
  }),
  methods: {
    regenerateAPIkeyEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
    async regenerateAPIKeyMethod() {
      console.log("console API is calling");
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(regenerateProjectApiKey, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.RegenerateProjectApiKey);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.loading = false;
        }
        this.regenerateAPIkeyvdialog = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
  },
};
</script>
