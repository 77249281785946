<template>
  <div>
    <regenerateAPIkey :regenerateAPIkeyvdialog="regenerateAPIkeyvdialog" @clicked="regenerateAPIkeyEmit" />
    <Overlay :overlay="overlay" />
    <ChangeOwnerShip
      :item="item"
      :StoreObj="getEachProjectDetails"
      :dialogChangeOwnership="dialogChangeOwnership"
      @clicked="dialogChangeOwnershipEmit"
    />
    <ChangeDeveloperLead
      :item="item"
      :StoreObj="getEachProjectDetails"
      :dialogChangeDeveloperLead="dialogChangeDeveloperLead"
      @clicked="dialogChangeDeveloperLeadEmit"
    />
    <ChangeTestLead
      :item="item"
      :StoreObj="getEachProjectDetails"
      :dialogChangeTestLead="dialogChangeTestLead"
      @clicked="dialogChangeTestLeadEmit"
    />
    <WithDrawnOwnershipRequest :dialogWithdrawOwnership="dialogWithdrawOwnership" @clicked="dialogChangeTestLeadEmit" />
    <v-card class="elevation-0">
      <v-toolbar dense class="elevation-0">
        <v-toolbar-title class="font-weight-bold">Project Details</v-toolbar-title>
      </v-toolbar>
      <v-card class="elevation-0 mx-5">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-row no-gutters v-for="(item, idx, i) in project_details" :key="i" class="pb-2">
              <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold fontSize">{{ idx }} </v-col>
              <v-col v-if="idx != 'Project Description'" cols="12" xs="12" sm="12" md="9" class="fontSize">
                :
                {{ item }}
                <span
                  class="fontSize"
                  v-if="i == 4 && $store.getters.get_current_project_details.ownership_transfer == 'TRUE'"
                >
                  Transfer Ownership Pending (On
                  {{ $store.getters.get_current_project_details.ownership_transfered_to }})
                </span>
              </v-col>
              <v-col v-else cols="12" xs="12" sm="12" md="9" class="fontSize">
                :
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" v-text="item.length < 200 ? item : `${item}...more`"></span>
                  </template>
                  <span v-text="item"></span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold fontSize">External Form</v-col>
              <v-col cols="12" xs="12" sm="12" md="9" class="fontSize pt-n2">
                <v-card-actions class="px-0 mx-0 mt-n4"
                  >: http://www.bugtrakr.com/BugReportForm/{{ project_details["Project ID"] }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        v-clipboard:copy="`http://www.bugtrakr.com/BugReportForm/${project_details['Project ID']}`"
                        v-clipboard:success="onCopyapiid"
                        class="mr-4"
                      >
                        <v-icon small color="primary">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy External Form URL</span>
                  </v-tooltip>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-if="
                getEachProjectDetails &&
                Object.keys(getEachProjectDetails).length != 0 &&
                (getEachProjectDetails.created_by == $store.getters.get_user_email ||
                  getEachProjectDetails.developer_lead_email_id == $store.getters.get_user_email ||
                  getEachProjectDetails.tester_lead_email_id == $store.getters.get_user_email)
              "
            >
              <v-col cols="12" xs="12" sm="12" md="3" class="font-weight-bold fontSize">API Key</v-col>
              <v-col cols="12" xs="12" sm="12" md="9" class="fontSize pt-n2">
                <v-card-actions class="px-0 mx-0 mt-n4"
                  >:<span v-on:mouseover="mouseover()" v-on:mouseleave="mouseleave()"> {{ api_key }}</span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        v-clipboard:copy="`${getEachProjectDetails.project_api_key}`"
                        v-clipboard:success="onCopyapiid"
                      >
                        <v-icon small color="primary">mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy API Key</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="
                      getEachProjectDetails &&
                      Object.keys(getEachProjectDetails).length != 0 &&
                      getEachProjectDetails.created_by == $store.getters.get_user_email
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon class="mr-4" @click="regenerateAPIkeyvdialog = true">
                        <!-- <v-btn v-bind="attrs" v-on="on" icon class="mr-4" @click="regenerateAPIKeyMethod()"> -->
                        <v-icon small @click="regenerateAPIkeyvdialog = true" color="primary">mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Regenerate API Key</span>
                  </v-tooltip>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined class="elevation-0" v-if="unmount == false">
              <apexchart type="line" height="200" :options="chartOptionsBar" :series="seriesBar"></apexchart>
            </v-card>
            <v-card outlined class="elevation-0 mt-2" v-if="overlay == false && checkLabelsToShow == true">
              <apexchart type="pie" height="200" :options="chartOptionsPie" :series="seriesPie"></apexchart>
            </v-card>
            <v-card
              outlined
              height="200"
              class="elevation-0 mt-2 text-center"
              v-if="overlay == false && checkLabelsToShow == false"
            >
              <div class="font-weight-bold mt-16">No Issues In Assigned Status Yet</div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Overlay from "@/components/Extras/Overlay.vue";

import regenerateAPIkey from "@/components/ProjectDetails/Dialogs/regenerateAPIkey.vue";
import ChangeOwnerShip from "@/components/ProjectDetails/Dialogs/ChangeOwnerShip.vue";
import ChangeDeveloperLead from "@/components/ProjectDetails/Dialogs/ChangeDeveloperLead.vue";
import ChangeTestLead from "@/components/ProjectDetails/Dialogs/ChangeTestLead.vue";
import WithDrawnOwnershipRequest from "@/components/ProjectDetails/Dialogs/WithDrawnOwnershipRequest.vue";
import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
import { GetAllIssuesCountOnDevelopers } from "@/mixins/GetAllIssuesCountOnDevelopers.js";
import { regenerateProjectApiKey } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    Overlay,
    ChangeOwnerShip,
    ChangeDeveloperLead,
    ChangeTestLead,
    WithDrawnOwnershipRequest,
    regenerateAPIkey,
  },
  mixins: [GetEachProjectDetails, GetAllIssuesCountOnDevelopers],
  data: () => ({
    item: "",
    overlay: false,
    regenerateAPIkeyvdialog: false,
    dialogChangeOwnership: false,
    dialogChangeDeveloperLead: false,
    dialogWithdrawOwnership: false,
    dialogChangeTestLead: false,
    project_details: {},
    StoreObj: {},
    chartOptionsBar: {
      chart: {
        type: "bar",

        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: 15,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Unacknowledged", "Acknowledged", "Assigned", "Fixed", "Closed", "Rejected"],
      },
      fill: {
        opacity: 1,
        colors: ["#2161A4"],
      },
    },
    chartOptionsPie: {
      labels: [],
      legend: {
        show: false,
      },
      fill: {
        colors: ["#3770AD", "#90B0D1", "#BCCFE3", "#E8EFF5", "#133A62"],
      },
      dataLabels: {
        enabled: true,
      },
      chart: {
        type: "pie",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          horizontal: false,
          columnWidth: "75%",
          endingShape: "rounded",
        },
      },
      stroke: {
        show: false,
        width: 5,
        colors: ["#3770AD", "#90B0D1", "#90B0D9", "#E8EFF5", "#133A62"],
      },
    },
    seriesBar: [
      {
        name: "Issue Status Count",
        type: "bar",
        data: [],
      },
    ],
    seriesPie: [],
    data_project_details: {},
    unmount: false,
  }),
  watch: {},
  async mounted() {
    await this.GetEachProjectDetailsMethod();
    this.unmount = true;
    this.$nextTick(() => {
      this.data_project_details = this.$store.getters.get_current_project_details;
      this.seriesBar[0].data[0] = this.data_project_details.no_of_unacknowledged_issues;
      this.seriesBar[0].data[1] =
        this.data_project_details.no_of_assigned_issues +
        this.data_project_details.no_of_fixed_issues +
        this.data_project_details.no_of_closed_issues;
      this.seriesBar[0].data[2] = this.data_project_details.no_of_assigned_issues;
      this.seriesBar[0].data[3] = this.data_project_details.no_of_fixed_issues;
      this.seriesBar[0].data[4] = this.data_project_details.no_of_closed_issues;
      this.seriesBar[0].data[5] = this.data_project_details.no_of_rejected_issues;
      this.unmount = false;
    });
    this.GetAllIssuesCountOnDevelopersMethod();
    this.$forceUpdate();
  },
  methods: {
    async regenerateAPIKeyMethod() {
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.$store.getters.get_current_project_details.project_id,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(regenerateProjectApiKey, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.RegenerateProjectApiKey);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          await this.GetEachProjectDetailsMethod();
        }
        this.loading = false;
      } catch (error) {
        console.log("Check The Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    mouseover() {
      this.api_key = this.getEachProjectDetails.project_api_key;
    },
    mouseleave() {
      this.api_key = "**********************************************";
    },
    dialogWithdrawOwnershipEmit(Toggle) {
      this.dialogWithdrawOwnership = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    widthDraw() {
      this.dialogWithdrawOwnership = true;
    },
    onCopyapiid() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "External Form Copied Successfully !!",
      };
    },
    checkItem(item) {
      this.item = item;
      switch (item) {
        case "Owner":
          this.dialogChangeOwnership = true;
          break;
        case "Developer Lead":
          this.dialogChangeDeveloperLead = true;
          break;
        case "Test Lead":
          this.dialogChangeTestLead = true;
          break;
      }
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.dialogChangeOwnership = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    dialogChangeDeveloperLeadEmit(Toggle) {
      this.dialogChangeDeveloperLead = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    dialogChangeTestLeadEmit(Toggle) {
      this.dialogChangeTestLead = false;
      if (Toggle == 2) {
        this.GetEachProjectDetailsMethod();
      }
    },
    regenerateAPIkeyEmit() {
      this.regenerateAPIkeyvdialog = false;
    },
  },
};
</script>
