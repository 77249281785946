<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-toolbar-title class="font-weight-bold">Activities</v-toolbar-title>
            <v-btn small icon color="primary" class="ml-3" @click="GetAllAuditLogsDetailsMethod(log_type)">
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field dense outlined class="FontSize borderRadius field_height field_label_size mt-6 mr-2" append-icon="mdi-magnify" label="Search" v-model="search"></v-text-field>
            <!-- <v-select dense outlined class="FontSize field_height field_label_size maxWidth mt-6 mr-2" label="Log Tye" :items="statusItems" v-model="log_type"></v-select> -->
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12"> </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          class="elevation-0"
          :fixed-header="true"
          :height="windowHeight"
          :headers="activitiesHeader"
          :items="GetAllAuditLogsDetailsList"
          :search="search"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [50, 100, 250, 500, 1000],
          }"
          :items-per-page="50"
        >
          <template v-slot:[`item.sl_no`]="{ item }">
            <div class="FontSize">
              {{ GetAllAuditLogsDetailsList.indexOf(item) + 1 }}
            </div>
          </template>
          <template v-slot:[`item.log_message`]="{ item }">
            <div class="FontSize">
              {{ item.log_message }}
            </div>
          </template>
          <template v-slot:[`item.log_type`]="{ item }">
            <div class="FontSize">
              {{ item.log_type }}
            </div>
          </template>
          <template v-slot:[`item.logged_by`]="{ item }">
            <div class="FontSize">
              {{ item.logged_by }}
            </div>
          </template>
          <template v-slot:[`item.log_time`]="{ item }">
            <div class="FontSize">
              {{ new Date(item.log_time).toLocaleString() }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllAuditLogsDetails } from "@/mixins/GetAllAuditLogsDetails.js";
export default {
  components: {
    Overlay,
  },
  mixins: [GetAllAuditLogsDetails],
  data: () => ({
    search: "",
    StoreObj: {},
    overlay: false,
    dialogDeveloper: false,
    dialogCreateDeveloper: false,
    activitiesHeader: [
      {
        text: "Sl No.",
        value: "sl_no",
      },
      {
        text: "Module Name",
        value: "log_type",
      },
      {
        text: "Action",
        value: "log_message",
      },
      {
        text: "Acted By",
        value: "logged_by",
      },
      {
        text: "Acted On",
        value: "log_time",
      },
    ],
    log_type: "ISSUES",
    statusItems: ["ISSUES", "TESTERS", "USERS", "PROJECTS", "DEVELOPERS", "OBSERVER"],
    windowHeight: 0,
  }),
  watch: {
    log_type() {
      this.GetAllAuditLogsDetailsMethod(this.log_type);
    },
  },
  mounted() {
    this.GetAllAuditLogsDetailsMethod(this.log_type);
    this.windowHeight = window.innerHeight - 160;
  },
  methods: {
    CheckItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      switch (action) {
        case "ACTION":
          this.dialogDeveloper = true;
          break;
      }
    },
    dialogDeveloperEmit(Toggle) {
      this.dialogDeveloper = false;
      if (Toggle == 2) {
        this.GetAllDeveloperAndTesterMethod("DEVELOPER", "MAIN");
      }
    },
    dialogCreateDeveloperEmit(Toggle) {
      this.dialogCreateDeveloper = false;
      if (Toggle == 2) {
        this.GetAllDeveloperAndTesterMethod("DEVELOPER", "MAIN");
      }
    },
  },
};
</script>
